<template lang="pug">
v-dialog(:value="value" @input="valueUpdated"  :width="380")
  v-card
    v-card-title {{ model && model.ID ? 'Edit ' : 'Add new '}}Course Leader
    v-card-text
      v-text-field(
        v-model="formData.name"
        label='Name'
        :error="formErrors.hasError('name')"
        :error-messages="formErrors.fields.name"
        data-test="cl-modal-field"
      )
    v-card-actions
      v-spacer
      v-btn(color="error" text @click="close" data-test="cl-modal-close") Close
      v-btn(color="success" :loading="processing" text @click="submit" data-test="cl-modal-submit") Save
  
</template>

<script>
import FormErrors from "@/util/form-errors"
import {CourseLeader} from "../core/models/courseLeaderModel";

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    processing: Boolean,
    model: CourseLeader
  },

  data: () => ({
    showing: false,
    formErrors: new FormErrors(),
    formData: {}
  }),
  
  mounted() {
    this.setDefault()
  },

  watch: {
    value(isShowing) {
      if(isShowing) this.setDefault()
    },
  },
  
  methods: {
    valueUpdated(val) {
      this.$emit('input', val)
    },
    
    setDefault() {
      if(!this.model) return;
      this.formData = { ...this.model, getApiData: this.model.getApiData }
    },
    
    submit() {
      this.$emit('submit', this.formData.getApiData())
    },

    close() {
      this.valueUpdated(false)
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>
